// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import 'alpinejs'
import Rails from '@rails/ujs'
import * as Sentry from "@sentry/browser";

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.consumer = consumer

StimulusReflex.initialize(application, { consumer, isolate: true, debug: process.env.RAILS_ENV === 'development' })

import debounced from 'debounced'
debounced.initialize({ input: { wait: 400 } })

window.Rails = Rails;
$.ajaxSetup({
  headers: {
    'X-CSRF-Token': Rails.csrfToken()
  }
});

// show progress indicator sooner
Turbo.navigator.delegate.adapter.showProgressBarAfterDelay = function () {
  // Save the timeout reference to allow clearing it later
  this.progressBarTimeout = setTimeout(() => {
    this.progressBar.show();
  }, 10);
};

document.addEventListener('page:loaded', function () {
  document.dispatchEvent(new Event('turbo:load'));
  document.dispatchEvent(new Event('ajax:complete'));
})

document.addEventListener('DOMContentLoaded', () => {
  const environment = document.body.dataset.environment;
  const sentryEnabledEnv = ['production', 'staging']

  if(sentryEnabledEnv.includes(environment)){
    Sentry.init({
      dsn: "https://65c9ea8f7d6149a8872d14c99173845e:c8e92c8fbdcd4752a6a5d7a1b6c9945e@o412916.ingest.sentry.io/5294345",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
})

document.addEventListener('stimulus-reflex:before', event => {
  window.safeStartTurboProgress();
})
document.addEventListener('stimulus-reflex:after', event => {

  window.safeStopTurboProgress();

  var end = document.location.href.indexOf('?') === -1 ? document.location.href.length : document.location.href.indexOf('?');
  var currentUrl = document.location.href.slice(0, end);

  if (window.eventUrl) {
    var eventUrl = window.eventUrl.slice(0, window.eventUrl.indexOf('?'));
  }

  if (currentUrl === eventUrl) {
    history.pushState({ turbo: true, url: window.eventUrl }, '', window.eventUrl);
  }
})
document.addEventListener('ajax:before', function () {
  window.safeStartTurboProgress();
});
document.addEventListener('ajax:complete', function () {
  window.safeStopTurboProgress();
});
document.addEventListener('turbo:push-history', function (event) {
  window.eventUrl = event.detail.url;
});

$(document).on('turbo:before-cache', function () {
  document.querySelectorAll('[data-turbo-temporary], .invalid-feedback, #toast-container').forEach((element) => {
    element.style.display = 'none';
  });

  window.clearEphemeralElements();

  $('.modal.show').modal('hide');
  $('.modal-backdrop').remove();
  $('body').removeClass('modal-open');
});

document.addEventListener("turbo:frame-missing", (event) => {
  if (event.detail.response.ok) {
    console.log("This page visit navigated via turbo:frame-missing event.")
    event.preventDefault()
    event.detail.visit(event.detail.response.url)

    Sentry.captureException({
      tags: {
        turbo: 'fetch',
        url: event.detail.response.url,
      },
      extra: {
        turbo_error: true,
        request_url: event.detail.url,
      },
    });
  }
})

document.addEventListener("turbo:before-stream-render", function(event) {
  if (event.target) {
    const frameId = event.target.getAttribute("target");
    // If the frame ID is invalid or does not exist in the DOM, log a warning
    const targetElement = document.getElementById(frameId);
    if (!targetElement) {
      var err_msg = `Turbo Stream Replace failed: Target element with ID "${frameId}" does not exist.`
      console.log(err_msg);
      Sentry.captureException({
          tags: {
            turbo: 'before-stream-render error',
            url: event.detail.url,
          },
          extra: {
            turbo_error: true
          },
      });
      event.preventDefault(); // Prevent the action if the ID is invalid
    }
  }
});

document.addEventListener("turbo:fetch-request-error", function(event) {
  // Log the error to the console
  console.error("Turbo Frame error:", event);
  Sentry.captureException({
      tags: {
        turbo: 'Turbo Frame error: before-stream-render',
        url: event.detail.url,
      },
      extra: {
        turbo_error: true
      },
  });
});
