import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  showForm(event) {
    const existing_company = document.getElementById("existing_company");
    const create_company = document.getElementById("create_company");

    existing_company.hidden = false;
    create_company.hidden = true;
  }
}
