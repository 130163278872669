import { Controller } from "stimulus";

const modelNames = ['variant', 'fulfillment', 'salesdraft', 'salesorder', 'warehousetote', 'warehousereceipt', 'goodsreceipt', 'transferorder', 'customer', 'supplier', 'purchaseorder', 'bin'];
const iconConfig = {
  'variant': 'tags',
  'fulfillment': 'truck-loading',
  'salesdraft': 'file-alt',
  'salesorder': 'inbox',
  'warehousetote': 'shopping-bag',
  'warehousereceipt': 'receipt',
  'goodsreceipt': 'dolly-flatbed',
  'transferorder': 'truck-moving',
  'customer': 'users',
  'supplier': 'shopping-cart',
  'purchaseorder': 'inbox',
  'bin': 'tasks',
  'pricelist': 'dollar-sign',
  'priceevent': 'calendar-alt',
};

const initializeLocalStorage = () => {
  let tabs_search = JSON.parse(localStorage.getItem("tabs_search")) || {};
  let global_search = JSON.parse(localStorage.getItem("global_search")) || {};

  if (!tabs_search || Object.keys(tabs_search).length === 0) {
    modelNames.forEach(modelName => {
      tabs_search[modelName] = { recently_viewed: [], recently_searched: [] };
    });
    localStorage.setItem("tabs_search", JSON.stringify(tabs_search));
  }

  if (!global_search || Object.keys(global_search).length === 0) {
    global_search = { recently_viewed: [], recently_searched: [] };
    localStorage.setItem("global_search", JSON.stringify(global_search));
  }

  return { tabs_search, global_search };
};


export default class extends Controller {
  static targets = ["searchInput", "suggestionList", "recentlyViewedList", "searchHistoryList", "globalSearchInput"];

  connect() {
    const { tabs_search, global_search } = initializeLocalStorage();
    this.tabs_search = tabs_search;
    this.global_search = global_search;
    document.addEventListener("click", this.handleClick.bind(this));
    this.addRecentlyViewedOnLoad()
    this.timeout = null;
  }

  focusInput() {
    const allowToSave = this.element.dataset.allowToSave;
    if (allowToSave != 'false') {
      const modelName = this.element.dataset.modelName;
      this.suggestionListTarget.classList.remove('d-none');
      this.loadSearchHistory(modelName);
      this.loadRecentlyViewedItems(modelName);
    }
  }

  hideSuggestionList() {
    if (this.hasSuggestionListTarget) {
      this.suggestionListTarget.classList.add('d-none');
    }
  }

  hideGlobalSuggestionList() {
    if (this.hasGlobalSuggestionListTarget) {
      this.globalSuggestionListTarget.classList.add('d-none');
    }
  }

  handleSearchInput(event) {
    clearTimeout(this.timeout);
    this.hideSuggestionList();
    if (event.key === "Escape") {
      document.activeElement.blur();
    } else {
      this.timeout = setTimeout(() => {
        const query = this.searchInputTarget.value.trim();
        if (query !== "") {
          this.saveSearchQuery(query);
        } else {
          this.suggestionListTarget.classList.remove('d-none');
        }
      }, 400);
    }
  }

  saveSearchQuery(query) {
    const allowToSave = this.element.dataset.allowToSave;
    if (allowToSave != 'false') {
      const modelName = this.element.dataset.modelName;
      let modelData = this.tabs_search[modelName];
      let modelSearchArray = this.tabs_search[modelName].recently_searched || [];
      modelSearchArray = modelSearchArray.filter(search => search !== query);
      modelSearchArray.unshift(query);
      this.tabs_search[modelName].recently_searched = modelSearchArray.slice(0, 5);
      localStorage.setItem("tabs_search", JSON.stringify(this.tabs_search));
      this.loadSearchHistory(modelName)
      this.globalSaveSearch(query)
    }
  }

  loadSearchHistory(modelName) {
    if (!this.tabs_search[modelName]) {
      this.tabs_search[modelName] = { recently_viewed: [], recently_searched: [] };
    }
    let modelData = this.tabs_search[modelName]
    let recentlySearched = modelData.recently_searched;
    const recentlySearchedHTML = recentlySearched.length > 0 ? `
      <h6 class='d-flex'>Recently Searched
        <span class='ml-auto cursor-pointer' data-action="click->search--user-activity#removeHistory">
          <i class="fas fa-trash remove_recent" data-model-name="${modelName}"></i>
        </span>
      </h6>
      ${recentlySearched.map(search => `
        <li>
          <a class="d-flex align-items-center cursor-pointer" data-action="click->search--user-activity#selectSearch">
            <div class="list-group-item-figure">
              <i class="fas fa-search"></i>
            </div>
            <div class="list-group-item-body">
              <div class="list-group-item-title">${search}</div>
            </div>
          </a>
        </li>
      `).join("")}
    ` : `
      <h6>Recently Searched</h6>
      <div class='recent-empty-results'>
        <div class='message d-flex'><i class="fas fa-search" aria-hidden="true"></i> No recently searched found</div>
      </div>`;
    this.searchHistoryListTarget.innerHTML = recentlySearchedHTML;
  }

  selectSearch(event) {
    event.preventDefault();
    const searchValue = event.currentTarget.querySelector('.list-group-item-title').textContent.trim();
    document.getElementById('full_search_hidden').value = searchValue;
    document.getElementById('full_search').value = searchValue;
    document.getElementById('search-form-tab').requestSubmit();
    this.hideSuggestionList();
  }

  addRecentlyViewed(event) {
    const modelName = event.currentTarget.getAttribute('data-model-name') || event.currentTarget.getAttribute('data-navigate-name-value');
    let title = event.currentTarget.getAttribute('data-title') || '';
    if (!title) {
      const target = event.currentTarget;
      title = target.querySelector('.font-weight-bolderer') 
              ? target.querySelector('.font-weight-bolderer').textContent.trim() 
              : target.querySelector('.list-group-item-title').textContent.trim();
    }else if(modelName != 'transferorder'){
      title = event.currentTarget.getAttribute('data-number') + " - " + title
    }
    let url = event.currentTarget.getAttribute('href')
    this.addRecent(modelName,title,url)
  }

  addRecentlyViewedOnLoad(){
    if(!(this.element.dataset.model === undefined || this.element.dataset.model == '')){
      let title = document.title.split('[LOCAL]').pop().trim();
      if (this.element.dataset.model == 'warehousereceipt' || this.element.dataset.model == 'warehousetote' || this.element.dataset.model == 'pricelist' || this.element.dataset.model == 'priceevent') {
        title = this.element.querySelector('.page-title').textContent.trim();
      }else if((this.element.dataset.model == 'salesorder' || this.element.dataset.model == 'salesdraft') && document.querySelector('.for-recent-view')?.textContent?.trim()){
        title = title + " - " + document.querySelector('.for-recent-view').textContent.trim()
      }
      let url = window.location.href
      const modelName = this.element.dataset.model
      this.addRecent(modelName,title,url)
    }
  }

  addRecent(modelName,title,url){
    if (!this.tabs_search[modelName]) {
      this.tabs_search[modelName] = { recently_viewed: [], search_history: [] };
    }

    let modelRecentArray = this.tabs_search[modelName].recently_viewed || [];
    modelRecentArray = modelRecentArray.filter(viewed => viewed.title !== title);
    let rv = { 'title': title, 'endpoint': url, 'model': modelName };
    modelRecentArray.unshift(rv);
    this.tabs_search[modelName].recently_viewed = modelRecentArray.slice(0, 5);
    localStorage.setItem("tabs_search", JSON.stringify(this.tabs_search));
    let recentArray = this.global_search.recently_viewed || [];
    recentArray = recentArray.filter(viewed => viewed.title !== title);
    let grv = { 'title': title, 'endpoint': url, 'model': modelName };
    recentArray.unshift(grv);
    this.global_search.recently_viewed = recentArray.slice(0, 5);
    localStorage.setItem("global_search", JSON.stringify(this.global_search));
  }

  loadRecentlyViewedItems(modelName) {
    if (!this.tabs_search[modelName]) {
      this.tabs_search[modelName] = { recently_viewed: [], search_history: [] };
    }
    let modelData = this.tabs_search[modelName]
    let viewedItems = modelData.recently_viewed;
    const recentViewHTML = viewedItems.length > 0 ? `
      <h6>Recently Viewed</h6>
      ${viewedItems.map(item => `
        <li>
          <a href="${item.endpoint}" class="d-flex align-items-center">
            <div class="list-group-item-figure">
              <i class="fas fa-${iconConfig[modelName]}"></i>
            </div>
            <div class="list-group-item-body">
              <div class="list-group-item-title">${item.title}</div>
            </div>
          </a>
        </li>
      `).join("")}
    ` : `
      <h6>Recently Viewed</h6>
      <div class='recent-empty-results'>
        <div class='message d-flex'><i class="fas fa-file" aria-hidden="true"></i> No recently viewed found</div>
      </div>`;
    this.recentlyViewedListTarget.innerHTML = recentViewHTML;
  }

  disconnect() {
    document.removeEventListener("click", this.handleClick.bind(this));
  }

  handleClick(event) {
    if (!this.element.contains(event.target) && !event.target.classList.contains('remove_recent')) {
      this.hideSuggestionList();
    }
  }

  handleGlobalSearchInput(event) {
    if (event.key == "Meta" || event.key == "ArrowDown" || event.key == "ArrowUp") return;
    clearTimeout(this.timeout);
    this.hideGlobalSuggestionList();
    this.timeout = setTimeout(() => {
      const query = this.globalSearchInputTarget.value.trim();
      if (query !== "") {
        this.globalSaveSearch(query);
      } else {
        setTimeout(() => { // Arrow function here to maintain 'this' context
          // for tubro stream render mention delay to get html element 
          var suggestionList = document.getElementById('globalSuggestionList');
          if (suggestionList) {
            this.loadGlobalRecentlySearched();
            this.loadGlobalRecentlyViewedItems();
          }
        }, 300);
      }
    }, 400);
  }

  globalSaveSearch(query){
    let searchArray = this.global_search.recently_searched || [];
    searchArray = searchArray.filter(search => search !== query);
    searchArray.unshift(query);
    this.global_search.recently_searched = searchArray.slice(0, 5);
    localStorage.setItem("global_search", JSON.stringify(this.global_search));
  }

  globalFocusInput(){
    const query = this.globalSearchInputTarget.value.trim();
    if (query == "") {
      this.loadGlobalRecentlySearched();
      this.loadGlobalRecentlyViewedItems();
    }
  }

  loadGlobalRecentlySearched() {
    let globalRecentlySearched = JSON.parse(localStorage.getItem("global_search")).recently_searched;
    const recentlySearchedHTML = globalRecentlySearched.length > 0 ? `
      <h6 class='d-flex'>Recently Searched
        <span class='ml-auto cursor-pointer' data-action='click->search--user-activity#removeGlobalHistory'>
          <i class="fas fa-trash remove_recent" id='remove_recent'></i>
        </span>
      </h6>
      ${globalRecentlySearched.map(search => `
        <li>
          <a class="d-flex align-items-center cursor-pointer" data-action="click->search--user-activity#globalSelectSearch">
            <div class="list-group-item-figure">
              <i class="fas fa-search"></i>
            </div>
            <div class="list-group-item-body">
              <div class="list-group-item-title" id='select_search'>${search}</div>
            </div>
          </a>
        </li>
      `).join("")}
    ` : `
      <h6>Recently Searched</h6>
      <div class='recent-empty-results'>
        <div class='message d-flex'>
          <i class="fas fa-search" aria-hidden="true"></i> No recently searched found
        </div>
      </div>`;
    document.getElementById('global-recent-search').innerHTML = recentlySearchedHTML;
  }

  loadGlobalRecentlyViewedItems() {
    let global_search = JSON.parse(localStorage.getItem("global_search"))
    let viewedItems = global_search.recently_viewed
    const recentViewHTML = viewedItems.length > 0 ? `
      <h6>Recently Viewed</h6>
      ${viewedItems.map(item => `
        <li>
          <a href="${item.endpoint}" class="d-flex align-items-center">
            <div class="list-group-item-figure">
              <i class="fas fa-${iconConfig[item['model']]}"></i>
            </div>
            <div class="list-group-item-body">
              <div class="list-group-item-title">${item.title}</div>
            </div>
          </a>
        </li>
      `).join("")}
    ` : `
      <h6>Recently Viewed</h6>
      <div class='recent-empty-results'>
        <div class='message d-flex'><i class="fas fa-file" aria-hidden="true"></i> No recently viewed found</div>
      </div>`;
    document.getElementById('global-recent-view').innerHTML = recentViewHTML;
  }

  globalSelectSearch(event) {
    event.preventDefault();
    let searchValue = event.currentTarget.querySelector('.list-group-item-title').textContent.trim();
    document.getElementById('global-search-input').value = searchValue;
    document.getElementById('global-search-form').requestSubmit();
    this.hideGlobalSuggestionList();
  }

  removeGlobalHistory(){
    if (localStorage.getItem('global_search')) {
      this.global_search.recently_searched = [];
      localStorage.setItem('global_search', JSON.stringify(this.global_search));
    }
    this.loadGlobalRecentlySearched()
  }

  removeHistory(event){
    const model_name = event.target.dataset.modelName;
    if (this.tabs_search[model_name].recently_searched) {
      this.tabs_search[model_name].recently_searched = [];
      localStorage.setItem('tabs_search', JSON.stringify(this.tabs_search));
    }
    this.loadSearchHistory(model_name)
  }
}
