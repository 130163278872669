import { Controller } from "stimulus";

export default class extends Controller {
  connect () {
    window.addEventListener('scanning', this.scan);
  }

  disconnect() {
    window.removeEventListener('scanning', this.scan);
  }

  scan(event) {
    // var scanObj = window.scan.cameraScanner();

    // Check if the user scan a bin label
    var binOption = $("modal.show, #pick_totes_line_item_bin_id option").filter(function() {
      return $(this).text().includes(event.value);
    })

    if (binOption != undefined) {
      binOption.prop("selected", true);
    } else {
      var code = event.value.replace('/', '').replace(' ', '').replace(',', '').replace('"', '').replace("'", '').replace(".", '').replace("&", '');

      if ($(`#pick-modal-${code}`).length > 0) {
        $(`#pick-modal-${code}`).modal('show');
      } else {
        // scanObj.playErrorAudio();
        alert('Item not in fulfillment');
      }
    };

    // scanObj.restart();
  }
}
