// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from '@rails/ujs';
Rails.start();

require("channels")
require("utils")

import * as Turbo from "@hotwired/turbo"
// import { Turbo } from "@hotwired/turbo-rails";
Turbo.session.drive = true

import "controllers"
import "scan"
import "vue_components"
import "trix"
import "@rails/actiontext"
Alpine.start()

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

import posthog from 'posthog-js'

posthog.init('phc_w1BqROhGkf39u7gToR162Pbe37e6s8QAgeWMrTQ0uxP',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
);

posthog.identify(
  getCookie('user_id'),
  { email: window.unescape(getCookie('user_email')), name: getCookie('user_name') }
);
