import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"];

  connect() {
    document.addEventListener('keydown', this.handleKeyPress);
    this.timeout = null;
  }

  handleKeyPress = (event) => {
    // Check if the pressed key is ESC (key code 27)
    if (event.key === 'Escape') {
      // Close the modal
      $(this.element).modal('hide');
    }
  }

  submitForm() {
    event.preventDefault();
    clearTimeout(this.timeout);
    const currentInputValue = this.inputTarget.value; // Store the current input value
    const searchForm = document.getElementById('search-form-tab')
    document.getElementById('full_search_hidden').value = currentInputValue
    const urlParams = new URLSearchParams(window.location.search);
    const searchScopeId = urlParams.get('search_scope_id');
    this.timeout = setTimeout(() => {
      if (document.contains(searchForm)) {
        searchForm.requestSubmit();
        if(searchScopeId){
          this.removeSelect()
        }
      }
    }, 400); // Adjust the delay as needed
  }

  removeSelect(){
    const tabs = document.querySelectorAll(".nav-link"); // Select tabs using class name
  }

  changeSearchValue(event){
    if(event.currentTarget.dataset.targetValue){
      document.getElementById('full_search').value = event.currentTarget.dataset.targetValue
    }else{
      document.getElementById('full_search').value = ""
    }
  }
}
