import Vue from 'vue/dist/vue.esm';
import VueResource from 'vue-resource';

Vue.use(VueResource);

function searchTerm(id, value, values, _destroy) {
  return { id: id, value: value, values: values, _destroy: _destroy };
}

function newSearchTerm() {
  return searchTerm(null, '', {}, null);
}

function initSearchClause(search_clause) {
  search_clause._destroy = null;

  if(!search_clause.search_terms) {
    search_clause.search_terms = [];
  }

  if(search_clause.search_terms.length === 0) {
    search_clause.search_terms.push(newSearchTerm());
  }

  search_clause.search_terms.forEach(function(search_term) {
    search_term._destroy = null;
  });
}

function hideSaveBtn() {
  var saveBtn = document.getElementById('save-search-btn')
  if(saveBtn){
    saveBtn.style.display = "none";
  }
}

Vue.component('selectize-input', {
  props: ['value', 'name', 'id', 'placeholder', 'endpoint', 'query-param', 'dependent-input-base-id', 'dependent-param-id', 'create', 'value-field', 'label-field', 'initial-options'],
  template: '<select class="form-control select" :name="name" :id="id" :placeholder="placeholder" data-type="select2"></select>',
  mounted: function () {
    let _this = this,
        data = [];
    if (_this.initialOptions && _this.initialOptions.length > 0) {
      for(let key in _this.initialOptions) {
        let initialOption = _this.initialOptions[key];
        data.push({ id: initialOption[_this.valueField], text: initialOption[_this.labelField] });
      }
    }

    const $select = $(_this.$el);
    $select.select2({
      data: data,
      ajax: {
        url: _this.endpoint,
        dataType: 'json',
        delay: 250,
        cache: true,
        data: function (params) {
          let requestParams = { q: params.term, type: 'select2', page: (params.page || 1) };

          if(_this.dependentParamId) {
            const dependentValue = document.getElementById(`${_this.dependentInputBaseId}_${_this.dependentParamId}`).value;

            if(dependentValue) {
              requestParams[_this.dependentParamId] = dependentValue
            }
          }

          return requestParams;
        }
      },
      closeOnSelect: true
    });

    // if(_this.dependentParamId) {
    //   if(document.getElementById(`${_this.dependentInputBaseId}_${_this.dependentParamId}`).value) {
    //     $select[0].selectize.enable();
    //   } else {
    //     $select[0].selectize.disable();
    //   }

    //   document.getElementById(`${_this.dependentInputBaseId}_${_this.dependentParamId}`).selectize.on('change', function() {
    //     $select[0].selectize.clearOptions();

    //     if(document.getElementById(`${_this.dependentInputBaseId}_${_this.dependentParamId}`).value) {
    //       $select[0].selectize.enable();
    //     } else {
    //       $select[0].selectize.disable();
    //     }
    //   })
    // }
  },
  beforeDestroy: function() {
    if ($(this.$el).data('select2')) {
      $(this.$el).select2('destroy');
    }
  }
});

document.addEventListener('turbo:load', initializeDynamicSearchForms, false);
document.addEventListener('turbo:frame-load', initializeDynamicSearchForms, false);

export function initializeDynamicSearchForms(){
  Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  let elements = document.querySelectorAll('.dynamic-search-form:not(.is--initialized)');

  if(elements.length > 0){
    
    for(var i = 0; i < elements.length; i ++) {
      const element = elements[i];
      element.classList.add("is--initialized");

      var search_clauses_attributes = JSON.parse(element.dataset.searchClausesAttributes);
      var search_options = JSON.parse(element.dataset.searchOptions);
      var initialSearchKey = element.dataset.initialSearchKey;

      // If no search clauses exist, add one automatically
      if (!search_clauses_attributes || search_clauses_attributes.length === 0) {
        var searchOptionKey = initialSearchKey || Object.keys(search_options)[0];
        var searchOption = search_options[searchOptionKey];

        search_clauses_attributes = [{
          id: null,
          search_key: searchOptionKey,
          custom_attribute_id: searchOption.custom_attribute_id,
          search_comparator: searchOption.comparators[0][0],
          search_terms: [newSearchTerm()],
          _destroy: null
        }];
      } else {
        search_clauses_attributes.forEach(initSearchClause);
      }

      const dynamicSearchApp = new Vue({
        el: element,
        data: function() {
          return {
            search_options: search_options,
            search_clauses_attributes: search_clauses_attributes,
            years_for_model: function(search_key, model_id) {
              var years = [],
                  data = search_options[search_key].years[model_id],
                  year_start = data.start,
                  year_end = data.end;

              for(var year = year_end; year >= year_start; year --) years.push(year);

              return years;
            },
            newSearchTerm: newSearchTerm,
            initSearchClause: function(searchClause, searchKey) {
              var searchOptions = search_options[searchKey];
              searchClause.search_comparator = searchOptions.comparators[0][0];
              searchClause.search_terms = [newSearchTerm()];

              if(searchOptions.custom_attribute_id) {
                searchClause.custom_attribute_id = searchOptions.custom_attribute_id;
              }
            }
          }
        },
        methods: {
          addSearchClause: function() {
            var searchOptionKey = initialSearchKey || Object.keys(search_options)[0],
                searchOption = search_options[searchOptionKey];

            search_clauses_attributes.push({
              id: null,
              search_key: searchOptionKey,
              custom_attribute_id: searchOption.custom_attribute_id,
              search_comparator: searchOption.comparators[0][0],
              search_terms: [newSearchTerm()],
              _destroy: null
            });

            hideSaveBtn();
          },
          removeSearchClause: function(searchClause) {
            searchClause._destroy = 1;
            hideSaveBtn();
          },
          addSearchTerm: function(searchClause) {
            searchClause.search_terms.push(newSearchTerm())
            hideSaveBtn();
          },
          isAllowRemoveSearchClause: function() {
            return true;
          },
          removeSearchTerm: function(searchClause, searchTerm) {
            searchTerm._destroy = 1;
            hideSaveBtn();
          },
          showSearchTermSeparator: function(searchClause, index) {
            var i = index - 1;
            while(i >= 0) {
              if(searchClause.search_terms[i]._destroy !== 1) {
                return true;
              }

              i --;
            }

            return false;
          },
          showSearchTermAddBtn: function(searchClause, index) {
            // only show if the searchClause is the last one (not destroyed)
            for (var i = index + 1; i < searchClause.search_terms.length; i ++) {
              if(searchClause.search_terms[i]._destroy !== 1) {
                return false;
              }
            }

            return true;
          },
          showSearchTermRemoveBtn: function(searchClause, index) {
            var activeTermsCount = 0;
            for(var i = 0; i < searchClause.search_terms.length; i ++) {
              if(searchClause.search_terms[i]._destroy !== 1) {
                activeTermsCount ++;
              }
            }

            return activeTermsCount > 1;
          }
        }
      });
    }
  }
}

document.addEventListener("turbo:before-cache", function () {
  document.querySelectorAll('.dynamic-search-form.is--initialized').forEach((element) => {
    element.classList.remove('is--initialized');
  });
});

